import router from "./router";
import { getToken } from "@/utils/auth";
import { useStore } from 'vuex'

const whiteList = ["/login"];
const store = useStore()

router.beforeEach((to, from, next) => {
  if (getToken()) {
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      if (store && store.getters.roles.length === 0) {
        // 현재 사용자가 user_info 정보를 가져왔는지 판단합니다
        store.dispatch("GetInfo").then(res => {
          // user_info 가져오기
          const roles = res.roles;
          store.dispatch("GenerateRoutes", { roles }).then(accessRoutes => {
            // roles 권한에 따라 접근 가능한 공유표를 생성합니다
            router.addRoutes(accessRoutes); // 액세스할 수 있는 공유표 동적으로 추가
            next({ ...to, replace: true }); // hack 메소드 addRoutes 완료됨
          });
        }).catch(() => {
          store.dispatch("LogOut").then(() => {
            // Message.error(err);
            next({ path: "/" });
          });
        });
      } else {
        next();
      }
    }
  } else {
    // token 없음
    if (whiteList.indexOf(to.path) !== -1) {
      // 리스트에 로그인하지 않고 바로 들어가다
      next();
    } else {
      // next(`/login?redirect=${to.fullPath}`); // 로그인 페이지로 바꿉니다
      next(`/login`); // 로그인 페이지로 바꿉니다
    }
  }
});

router.afterEach(() => {
  // NProgress.done();
});
