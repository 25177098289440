<template>
  <ion-header translucent>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="setClose"><ion-icon :icon="closeCircleOutline"></ion-icon></ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-card>
      <ion-item>
        <h3>LOGIN</h3>
      </ion-item>
      <ion-item>
        <ion-label position="floating">아이디</ion-label>
        <ion-input id="username" v-model="loginForm.username" required></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="floating">비밀번호</ion-label>
        <ion-input type="password" id="password" v-model="loginForm.password" required></ion-input>
      </ion-item>

      <ion-item>
        <ion-button type="button" @click="handleLogin">
          로그인
          <ion-icon slot="end" :icon="logIn"></ion-icon>
        </ion-button>
        <ion-button type="button" router-link="/signup">
          회원가입
          <ion-icon slot="end" :icon="personAdd"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-card>
  </ion-content>  
</template>

<script>
import { 
  IonHeader, 
  IonToolbar, 
  IonTitle,
  IonButtons, 
  IonButton, 
  IonContent,
  IonCard,
  IonItem,
  IonLabel,
  IonInput,
  IonIcon
} from '@ionic/vue'
import { defineComponent, reactive, computed } from 'vue'
// import { useRouter } from 'vue-router'
import { logIn, personAdd, closeCircleOutline } from 'ionicons/icons'
import { decrypt, encrypt } from "@/utils/jsencrypt"
import Cookies from "js-cookie"
import md5 from "js-md5"
import { useStore } from 'vuex'

export default defineComponent({
  components: { 
    IonHeader, 
    IonToolbar, 
    IonTitle,
    IonButtons, 
    IonButton, 
    IonContent,
    IonCard,
    IonItem,
    IonLabel,
    IonInput,
    IonIcon
  },
  props: {
    title: {
      type: String,
      default: '로그인'
    },
    modalData: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    // const router = useRouter()

    const store = useStore()

    // store state
    const token = computed(() => store.state.User.token)
    
    // store actions
    const fetchLogin = () => store.dispatch('User/fetchLogin', param)

    let loginForm = reactive({
      username: undefined,
      password: undefined,
      rememberMe: undefined
    })

    let param = reactive({})

    const setClose = () => {
      emit('close', false)
    }

    // 로그인
    const handleLogin = async () => {
      if (loginForm.rememberMe) {
        Cookies.set("username", loginForm.username, { expires: 30 })
        Cookies.set("password", encrypt(loginForm.password), { expires: 30 })
        Cookies.set("rememberMe", loginForm.rememberMe, { expires: 30 })
      } else {
        Cookies.remove("username")
        Cookies.remove("password")
        Cookies.remove("rememberMe")
      }

      param = Object.assign({}, loginForm)
      param.password = md5(param.password)
      await fetchLogin(param)
      
      if (token.value) {
        setClose()
      }
    }

    const getCookie = () => {
      const username = Cookies.get("username")
      const password = Cookies.get("password")
      const rememberMe = Cookies.get("rememberMe")
      loginForm = {
        username: username === undefined ? loginForm.username : username,
        password: password === undefined ? loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      }
    }

    return {
      logIn,
      personAdd,
      closeCircleOutline,
      // decrypt, 
      // encrypt,
      md5,
      loginForm,
      setClose,
      handleLogin,
      getCookie,

      // store state
      token,

      // store actions
      fetchLogin
    }
  }
})
</script>