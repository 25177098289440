<template>
  <ion-page>
    <ion-content class="ion-padding">
      <ion-router-outlet id="main"></ion-router-outlet>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonContent, 
  IonRouterOutlet,
  IonPage, 
} from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Empty',
  components: {
    IonContent, 
    IonRouterOutlet,
    IonPage
  }
})
</script>