import { createRouter, createWebHistory } from '@ionic/vue-router';
const routes = [
	{
		path: '/',
		component: () => import('@/views/main/Main'),
		redirect: '/new-request/D',
		children: [
			{
				path: '/new-request/:instl_aprv_yn',
				component: () => import('@/views/main/New-request')
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/views/login/Login')
	},
	{
		path: '/my',
		component: () => import('@/views/login/My')
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
