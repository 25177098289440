<template>
  <ion-app>
    <component :is="layout" />
  </ion-app>
</template>

<script>
import DefaultLayout from '@/layouts/default'
import EmptyLayout from '@/layouts/empty'
import { IonApp } from '@ionic/vue'
import { useRoute } from 'vue-router'
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    DefaultLayout,
    EmptyLayout
  },
  setup() {
    const route = useRoute()
    const layout = computed(() => {
      
      let resultLayout = DefaultLayout;
      switch (route.fullPath) {
        case "/login":
          resultLayout = EmptyLayout
          break;
        default:    
      }
      return resultLayout
    })

    return {
      layout
    }
  }
})
</script>