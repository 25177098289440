<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>
          <ion-img src="assets/logo.svg"></ion-img>
        </ion-title>
        <ion-buttons slot="end">
          <template v-if="token">
            <ion-button @click="handleMyPage">
              <ion-avatar>
                <img src="https://ionicframework.com/docs/demos/api/avatar/avatar.svg" />
              </ion-avatar>
            </ion-button>
          </template>
          <template v-else>
            <ion-button @click="handleLogin">
              <ion-icon :icon="personOutline"></ion-icon>
            </ion-button>
          </template>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-router-outlet id="main"></ion-router-outlet>
    </ion-content>

    <ion-modal
      :is-open="isOpenRef"
      css-class="my-custom-class"
      @didDismiss="setOpen(false)"
    >
      <LoginModal width="80%" @close="setClose"></LoginModal>
    </ion-modal>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonRouterOutlet,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonPage,
  IonIcon,
  IonAvatar,
  IonModal,
  loadingController,
} from '@ionic/vue';
import { defineComponent, ref, computed, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ellipse, square, personOutline, clipboardOutline } from 'ionicons/icons';
import LoginModal from '@/components/modal/LoginModal';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Default',
  components: {
    IonContent,
    IonHeader,
    IonRouterOutlet,
    IonButtons,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonPage,
    IonIcon,
    IonAvatar,
    IonModal,
    LoginModal,
  },
  setup() {
    const eventBus = inject('$eventBus');
    const router = useRouter();
    const store = useStore();
    const token = computed(() => store.state.User.token);
    let loading = ref(undefined);

    let isOpenRef = ref(false);
    onMounted(() => {
      eventBus.on('loading', (power, loadingText) => {
        handleLoading(power, loadingText);
      });
    });

    const handleLoading = async (power, loadingText = '로딩중...') => {
      if (power) {
        loading.value = await loadingController.create({
          cssClass: 'my-custom-class',
          message: loadingText,
        });
        await loading.value.present();
      } else {
        await loading.value.dismiss();
      }
    };

    const setOpen = (state) => (isOpenRef.value = state);

    const setClose = (state) => {
      isOpenRef.value = state;
    };

    const handleLogin = () => {
      setOpen(true);
    };

    const handleMyPage = () => {
      router.push({
        path: '/my',
      });
    };

    return {
      ellipse,
      square,
      personOutline,
      clipboardOutline,
      loading,
      isOpenRef,
      handleLoading,
      setOpen,
      setClose,
      handleLogin,
      handleMyPage,

      // store state
      token,
    };
  },
});
</script>

<style scoped>
.title-default :deep ion-img {
  width: 100px;
  margin: 0 auto;
}

.ion-activatable :deep ion-avatar {
  width: 32px;
  height: 32px;
}
:deep ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
}
</style>
